import api from "../axios_service.js";

// Produtos
export async function getAllProdutos(filtro) {
  const response = await api.get(`/produtos${filtro ? filtro : ""}`);
  return response.data.result;
}

export async function getAllProdutosNotKit(filtro) {
  const response = await api.get(`/produtos-not-kit${filtro ? filtro : ""}`);
  return response.data.result;
}

export async function buscaProdutos(filtros) {
  const response = await api.get(`/busca-produtos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function buscaProdutosDialog(filtros) {
  const response = await api.get(
    `/busca-produtos-dialog${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function buscaProdutosDialogMultiDeposito(filtros) {
  const response = await api.get(
    `/busca-produtos-dialog-multi-deposito${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getOneProduto(id, filtros) {
  const response = await api.get(`/produtos/${id}${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function getOneProdutoPiscina(id, filtros) {
  const response = await api.get(
    `/produtos-piscina/${id}${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchCustoProduto(id) {
  const response = await api.get(`/custo-produtos/${id}`);
  return response.data.result;
}
export async function putProdutos(id, produtos) {
  return api.post(`/produtos/${id}`, produtos);
}
export async function putProdutosKit(id, produtos) {
  return api.post(`/produtos-kit/${id}`, produtos);
}
export async function postProdutos(produtos) {
  return api.post("produtos/add", produtos);
}
//check se tem movimento no produto
export async function getCheckProdutoMovimento(id) {
  const response = await api.get(`/produtos-check-movimento/${id}`);
  return response.data.result;
}
//desativar um produto
export async function postDesativarProduto(id) {
  return api.post(`/produtos-desativar/${id}`);
}
//Deleta um produto
export function deleteProduto(id) {
  return api.delete(`/produtos-deletar/${id}`);
}

// Produtos Classificacoes
export async function getAllProClass(filtros) {
  const response = await api.get(
    `/produtoscategorias${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function getAllProClassAtivo() {
  const response = await api.get("/produtoscategorias/ativo");
  return response.data.result;
}

export async function getAllProdutosKit(id) {
  const response = await api.get(`/produtos-kit/${id}`);
  return response.data.result;
}

export async function getAllProdutosKitVenda(id) {
  const response = await api.get(`/produtos-kit-vendas/${id}`);
  return response.data.result;
}

export async function putProClass(id, categoria) {
  let body = {};
  for (let key in categoria) {
    body[key] = categoria[key];
  }
  return api.put(`/produtoscategorias/${id}`, body);
}

export async function postProClass(categoria) {
  let body = {};
  for (let key in categoria) {
    body[key] = categoria[key];
  }
  return api.post("produtoscategorias/add", body);
}

// Produtos Movimentações
export async function getProdutoMovimentacoes(filtros) {
  const response = await api.get(`/produtosmovimentos${filtros}`);
  return response.data.result;
}

export async function putProdutoMovimentacoes(id, movimentacao) {
  let body = {};
  for (let key in movimentacao) {
    body[key] = movimentacao[key];
  }
  return api.put(`/produtosmovimentos/${id}`, body);
}

export async function postProdutoMovimentacoes(movimentacao) {
  return api.post("/produtosmovimentos/add", movimentacao);
}

export async function postTransferencia(transferencia) {
  return api.post("/produtos-transferencia/add", transferencia);
}

// Depositos
// Produtos Movimentações
export async function fetchDepositos(filtros) {
  let response = await api.get(`/produtosdepositos${filtros ? filtros : ""}`);
  return response.data.result;
}

export async function Api_postDeposito(deposito) {
  let body = {};
  for (let key in deposito) {
    body[key] = deposito[key];
  }
  return api.post("produtosdepositos/add", body);
}

export async function Api_putDeposito(id, deposito) {
  let body = {};
  for (let key in deposito) {
    if (key != "cidade") {
      body[key] = deposito[key];
    }
  }
  return api.put(`/produtosdepositos/${id}`, body);
}

export function deleteDeposito(id) {
  return api.delete(`/produtosdepositos/${id}`);
}

export async function postEstoqueMinimo(estoque) {
  const response = api.post(`/controle-estoque`, estoque);
  return response;
}

export async function updateProdutoKitOS(id, produto){
  const response = await api.put(`/update-produto-kit-os/${id}`, produto);
  return response;
}

export async function updateProdutoOS(id, produto){
  const response = await api.put(`/update-produto-os/${id}`, produto);
  return response;
}
