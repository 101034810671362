<template>
  <div class="ml-4 mt-4">
    <span class="text-subtitle-1">{{ $tc("global.produto", 2) }}</span>
    <v-row dense class="mt-1">
      <v-col cols="12" md="2">
        <v-combobox
          v-model="selectedDeposito"
          :label="$tc('global.deposito', 2)"
          :items="depositos"
          :disabled="disableDeposito || disabled"
          item-text="deposito"
          item-value="deposito_id"
          dense
          hide-details
          outlined
        ></v-combobox>
      </v-col>
      <v-col cols="12" md="4">
        <SearchProdutosMultiDeposito
          :disableAll="disabled"
          :produto_sync.sync="selectedProduto"
          :lista_preco_id="selectedLista ? selectedLista.lista_preco_id : null"
          :deposito_id="selectedDeposito ? selectedDeposito.deposito_id : null"
          :deposito_descricao="
            selectedDeposito ? selectedDeposito.deposito : ''
          "
          :moeda_id="selectedMoeda ? selectedMoeda.id_moeda : null"
          tipo="simples"
          ref="buscaProduto"
          typeComp="venda"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :disabled="disabled"
          v-model.number="qtde_produto"
          :placeholder="$tc('global.qtd')"
          hide-details
          dense
          solo
          flat
          outlined
          class="mx-3 input-center"
          append-outer-icon="mdi-plus"
          @click:append-outer="qtde_produto++"
          prepend-icon="mdi-minus"
          @click:prepend="qtde_produto > 1 ? qtde_produto-- : ''"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn outlined color="button_1" @click="validaValorProduto">{{
          $tc("global.add")
        }}</v-btn>
      </v-col>
    </v-row>
    <v-card flat class="pr-4">
      <v-divider class="mt-2"></v-divider>
      <v-data-table
        :headers="headers"
        :items="produtos"
        :items-per-page="-1"
        hide-default-footer
        ref="produtosTable"
        class="data-tables data-tables__row-click"
      >
        <template v-slot:item.actions="{ item, index }">
          <template
                v-if="isItemEditing && index === indexItemEditing"
              >
                <v-btn
                  color="green lighten-1"
                  icon
                  small
                  @click.stop="updateProduto(index)"
                >
                  <v-icon size="20">mdi-content-save</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-tooltip bottom color="darkblue">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="controlePrecos && controlePrecos[index] && controlePrecos[index].preco_alterado"
                      icon
                      small
                      @click.stop="confirmarMudancaPreco(index)"
                      v-on="on" 
                    >
                      <v-icon size="20">mdi-check-bold</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $tc("global.confirmar") + " " + $tc("global.preco") }}
                  </span>
                </v-tooltip>
                <v-btn
                  icon
                  small
                  :disabled="disabled"
                  @click.stop="StartEditItem(item, index)"
                >
                  <v-icon size="20">mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                  color="red lighten-2"
                  icon
                  small
                  :disabled="disabled"
                  @click.stop="deletarItem(index)"
                >
                  <v-icon size="20">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
        </template>
        <template v-slot:item.preco="{ item, index }">
          <div v-show="isItemEditing && index === indexItemEditing">
            <div class="d-flex align-center justify-center">
              <v-text-field
                :disabled="disabled"
                v-model="itemEditingPreco"
                :placeholder="$tc('global.preco')"
                hide-details
                outlined
                dense
                class="input-low-height"
                ref="itemEditingPreco"
                :error="invalidPrecoItemEdit"
                :append-icon="
                  invalidPrecoItemEdit ? 'mdi-lock-alert-outline' : ''
                "
                @click:append="openDialogAuth(true)"
                v-currency="vCurrencyOptions"
                @keydown.enter.prevent="updateProduto(index)"
              ></v-text-field>
              <v-tooltip bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="small" class="ml-1"
                    >mdi-information-variant
                  </v-icon>
                </template>
                <span>{{ $tc("global.precoMinimo") }}</span>
                <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                  {{ item.preco_minimo | guarani }}
                </span>
                <span v-else>{{ item.preco | currency }} </span>
              </v-tooltip>
              <v-tooltip v-if="item.preco_alterado" bottom color="darkblue">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    size="large"
                    class="ml-1"
                    :color="
                      controlePrecos[index].preco_alterado ? 'error' : 'success'
                    "
                    >mdi-cash-lock</v-icon
                  >
                </template>
                <span v-show="controlePrecos[index].preco_alterado">
                  {{ $tc("global.necessarioConfirmarPreco") }} <br />
                </span>
                <span>
                  {{ $tc("global.precoNovoCliente") }}
                  <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                    {{ item.novo_preco | guarani }}
                  </span>
                  <span v-else>{{ item.novo_preco | currency }} </span>
                </span>
              </v-tooltip>
            </div>
          </div>
          <div v-show="!isItemEditing && index != indexItemEditing">
            <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
              {{ item.preco | guarani }}
            </span>
            <span v-else>{{ item.preco | currency }} </span>
            <v-tooltip bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" size="small" class="ml-1"
                  >mdi-information-variant
                </v-icon>
              </template>
              <span>{{ $tc("global.precoMinimo") }}</span>
              <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                {{ item.preco_minimo | guarani }}
              </span>
              <span v-else>{{ item.preco_minimo | currency }} </span>
            </v-tooltip>
            <v-tooltip v-if="item.preco_alterado" bottom color="darkblue">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  size="large"
                  class="ml-1"
                  :color="
                    controlePrecos[index].preco_alterado ? 'error' : 'success'
                  "
                  >mdi-cash-lock</v-icon
                >
              </template>
              <span v-show="controlePrecos[index].preco_alterado">
                {{ $tc("global.necessarioConfirmarPreco") }} <br />
              </span>
              <span>
                {{ $tc("global.precoNovoCliente") }}
                <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                  {{ item.novo_preco | guarani }}
                </span>
                <span v-else>{{ item.novo_preco | currency }} </span>
              </span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.valor_total="{ item, index }">
          <div v-show="!isItemEditing && index != indexItemEditing">
            <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
              {{ item.preco * item.qtde | guarani }}
            </span>
            <span v-else>{{ item.preco * item.qtde | currency }} </span>
          </div>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <div style="min-width: 45%">
          <div class="d-flex py-2">
            <span class="text-subtitle-1 grey--text">{{
              $tc("global.total")
            }}</span>
            <v-spacer></v-spacer>
            <span class="mr-16 pr-13">
              <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
                {{ totalProdutosComputed | guarani }}
              </span>
              <span v-else>
                {{ totalProdutosComputed | currency }}
              </span>
            </span>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { fetchUsersDeposito } from "@/api/usuarios/usuarios.js";
import { postEstoqueMinimo, getOneProduto } from "@/api/produtos/produtos.js";
import { mapState } from "vuex";
export default {
  name: "OrdemServicoManualProdutos",
  props: {
    selectedMoeda: {
      type: Object,
      default: () => ({}),
    },
    selectedLista: {
      type: Object,
      default: () => ({}),
    },
    produtos: {
      type: Array,
      default: () => [],
    },
    depositoId: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SearchProdutosMultiDeposito: () =>
      import("@/components/fields/SearchProdutosMultiDeposito.vue"),
  },

  data() {
    return {
      loading: false,
      controlePrecos: [],
      depositos: [],
      selectedProduto: null,
      produto: {
        qtde: 1,
      },
      qtde_produto: 1,
      estoque_disponivel: 0,
      loadingEstoque: false,
      prestadores: [],
      itemEditingPreco: null,
      itemPreco: null,
      invalidPrecoItem: false,
      invalidPrecoItemEdit: false,
      itemEditing: null,
      selectedDeposito: null,
      isItemEditing: false,
      indexItemEditing: null,
    };
  },

  watch: {
    qtde_produto() {
      this.produto.qtde = this.qtde_produto;
    },
    selectedProduto() {
      if (this.selectedProduto) {
        this.getProduto();
      }
    },
    selectedDeposito() {
      if (this.selectedDeposito) {
        this.$emit("update:depositoId", this.selectedDeposito.deposito_id);
      }
    },
    StartEditItem(item, index) {
      this.isItemEditing = true;
      this.itemEditing = { ...item };
      this.preco_minimo = item.preco;
      this.indexItemEditing = index;
      this.controleEstoque(this.itemEditing.produto_id);

      this.$ci.setValue(this.$refs.itemEditingPreco, item.preco);
    },
    itemPreco() {
      this.produto.preco = this.$ci.parse(
        this.itemPreco,
        this.vCurrencyOptions
      );
    },
    itemEditingPreco() {
      if (this.isItemEditing) {
        this.itemEditing.preco = this.$ci.parse(
          this.itemEditingPreco,
          this.vCurrencyOptions
        );
      }
    },
  },

  computed: {
    ...mapState("Usuario", {
      user_id: (state) => state.usuario.id,
    }),
    totalProdutosComputed() {
      return this.produtos.reduce((acc, item) => {
        return Number(acc) + Number(item.preco) * Number(item.qtde);
      }, 0);
    },
    disableDeposito() {
      return this.produtos.length > 0;
    },

    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda
          ? this.selectedMoeda.sigla === "G$"
            ? 0
            : undefined
          : "",
        distractionFree: false,
      };
    },

    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          value: "descricao",
          width: "40vw",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
          align: "center",
        },
        {
          text: this.$tc("global.valorUnitario"),
          value: "preco",
          align: "right",
          width: "150px",
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.total"),
          value: "valor_total",
          align: "right",
          width: "150px",
        },
        {
          text: this.$tc("global.del"),
          value: "actions",
          width: "100px",
          sortable: false,
          align: "right",
        },
      ];
    },
  },

  methods: {
    async getDepositos() {
      const response = await fetchUsersDeposito(`?users_id=${this.user_id}`);
      this.depositos = response;
      this.selectedDeposito = this.depositos.find((deposito) => {
        return deposito.principal == true;
      });
    },

    confirmarMudancaPreco(index){
      this.controlePrecos[index].preco_alterado = false;
      this.key++;
    },

    validaValorProduto() {
      let produto_preco = this.$ci.parse(
        this.produto.preco,
        this.vCurrencyOptions
      );
      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItem = true;
      } else {
        if (
          produto_preco < 0 ||
          produto_preco < this.produto.preco_minimo ||
          isNaN(produto_preco)
        ) {
          this.$toast.error("Valor do produto menor que o minimo do atacado");
          this.erroPrecoItem = "Valor do produto menor que o minimo do atacado";

          this.invalidPrecoItem = true;
        } else {
          this.erroPrecoItem = "";
          this.invalidDesconto = false;
          this.AddProduto();
        }
      }
    },
    async getProduto() {
      let filtro = `?lista_preco_id=${this.selectedLista.id}`;
      if(this.selectedMoeda) {
        filtro += `&moeda_id=${this.selectedMoeda.id_moeda}`;
      }

      const produto = await getOneProduto(this.selectedProduto.id, filtro);

      if (!produto) {
        this.$toast.warning(
          "Produto sem preço registrado nesta lista e com essa moeda!"
        );
        this.ClearAddProduto();
        return;
      }
      this.produto.cif = Number(produto.cif);
      this.produto.produto_id = produto.id;
      this.produto.descricao = produto.descricao;
      this.produto.preco_minimo = produto.preco_minimo;
      this.produto.preco = produto.preco;
      this.produto.controle_estoque = produto.controle_estoque;
      this.produto.precoFormatado = this.$ci.parse(
        this.produto.preco,
        this.vCurrencyOptions
      );
      if (produto.img) {
        this.produto.img = produto.img;
      }
      if (produto.controle_estoque == 1) {
        await this.controleEstoque(produto.id, this.produto.qtde);
      } else {
        this.estoque_disponivel = null;
      }
    },

    async controleEstoque(itemId, qtde) {
      this.loadingEstoque = true;

      const produtoEstoque = {
        produtoId: itemId,
        depositoId: this.depositoId,
      };
      const response = await postEstoqueMinimo(produtoEstoque);
      this.estoque_disponivel = response.data.result;
      this.loadingEstoque = false;
    },

    AddProduto() {
      this.loadingAddProduto = true;
      let limite_estoque = this.limiteEstoqueAdd(
        this.produto.produto_id,
        this.produto.qtde,
        this.produto.controle_estoque
      );

      if (this.produto.qtde >= 0 && !limite_estoque) {
        let produto = { ...this.produto };
        this.produtos.push(produto);

        this.$nextTick().then(() => {
          this.ClearAddProduto();
          this.$refs.buscaProduto.$refs.buscaProduto.focus();
        });

        this.invalidPrecoItem = false;
        this.invalidPrecoItemEdit = false;
        this.invalidDesconto = false;
        this.erroPrecoItem = "";
        this.loadingAddProduto = false;
      }
      if (!this.qtde_produto || this.qtde_produto <= 0) {
        this.$toast.error("Quantidade minima para adicionar o item é 1!");
        this.produto.qtde = 1;
        this.loadingAddProduto = false;
      }
      if (limite_estoque) {
        this.$toast.error("Quantidade de itens indisponível no estoque!");
        this.loadingAddProduto = false;
      }
    },

    limiteEstoqueAdd(id, qtde, controle_estoque) {
      if (controle_estoque == 0) {
        return false;
      } else {
        let totaltems = 0;
        let itemsSelecionado = this.produtos.filter(
          (item) => item.produto_id === id
        );
        const reducer = (accumulator, currentValue) => {
          return accumulator + Number(currentValue.qtde);
        };
        totaltems = itemsSelecionado.reduce(reducer, 0);
        if (this.isItemEditing) {
          let verificaLimite =
            this.estoque_disponivel -
            (totaltems - this.produtos[this.indexItemEditing].qtde) -
            qtde;

          verificaLimite =
            verificaLimite +
            Number(this.produtos[this.indexItemEditing].qtde);
          return verificaLimite < 0 ? true : false;
        } else {
          let verificaLimite = this.estoque_disponivel - qtde;
          return verificaLimite < 0 ? true : false;
        }
      }
    },
    
    ClearAddProduto() {
      this.selectedProduto = null;
      this.produto.produto_id = null;
      this.produto.descricao = null;
      this.produto.qtde = 1;
      this.produto.preco = null;
      this.produto.cif = null;
      this.produto.img = null;
      this.qtde_produto = 1;
      this.$ci.setValue(this.$refs.itemPreco, null);
    },

    StartEditItem(item, index) {
      this.isItemEditing = true;
      this.itemEditing = { ...item };
      this.preco_minimo = item.preco;
      this.indexItemEditing = index;
      this.controleEstoque(this.itemEditing.produto_id);
      this.$ci.setValue(this.$refs.itemEditingPreco, item.preco);
    },

    updateProduto(index) {
      let limite_estoque = this.limiteEstoqueAdd(
        this.produtos[this.indexItemEditing].produto_id,
        this.itemEditing.qtde,
        this.produtos[this.indexItemEditing].controle_estoque
      );

      let preco_minimo = this.$ci.parse(
        this.produtos[this.indexItemEditing].preco_minimo,
        this.vCurrencyOptions
      );

      let produto_preco = (this.produtos[this.indexItemEditing].preco =
        this.itemEditing.preco);

      if (
        produto_preco === null ||
        produto_preco === undefined ||
        produto_preco === ""
      ) {
        this.invalidPrecoItemEdit = true;
      } else {
        if (
          produto_preco < 0 ||
          produto_preco < preco_minimo ||
          isNaN(produto_preco)
        ) {
          this.$toast.error("Valor do produto menor que o minimo do atacado");
          this.invalidPrecoItemEdit = true;
        } else {
          if (!limite_estoque) {
            this.produtos[this.indexItemEditing].preco =
              this.itemEditing.preco;
            this.produtos[this.indexItemEditing].qtde =
              this.itemEditing.qtde;

            this.isItemEditing = false;
            this.itemEditing = null;
            this.indexItemEditing = null;
          } else {
            this.$toast.error("Quantidade de items indisponivel no estoque!");
          }
        }
      }
      this.confirmarMudancaPreco(index);
    },

    deletarItem(index) {
      this.produtos.splice(index, 1);
    },
  },

  mounted() {
    this.getDepositos();
  },
};
</script>
